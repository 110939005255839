
.image-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  &__image-info {
    padding-top: 20px;
    display: contents;
  }

  .analysis-details {
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 1000;
  }
}

.image-container>div:only-child {
  grid-column: 1 / -1;
}

@media (max-width: 768px) {
  .image-container {
    grid-template-columns: 1fr;
  }
}

.page-error {
  width: 400px;
  height: 700px;
  padding: 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-title {
    color: #ac0000;
    font-size: 1.2em;;
  }

  .error-details {
    width: 300px;
    color: black;
    text-align: left;
    margin-top: 10px;
    font-size: 0.8em;
    padding: 10px;
    border: 1px solid #666;
  }

  .error-details-title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  strong {
    display: inline;
    font-weight: 500;
  }

  .fullscreen-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
}
