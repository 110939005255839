@import '../variables';

.tabBackground {
  background-color: $grey-5!important;
  border-color: $grey-5!important;
}

.tabDialog {
  width: 400px;
}
.align-button-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.hover-username:hover, .hover-email:hover, .hover-actions:hover, .hover-permission:hover {
  cursor: pointer;
  background-color: #f1e0d7;
  border-radius: 50px;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.hover-username, .hover-email, .hover-actions, .hover-permission {
  border: 2px solid transparent;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  box-sizing: border-box;
}

.hover-username:hover, .hover-email:hover, .hover-actions:hover, .hover-permission:hover {
  border-color: #f0d4c6;
}
