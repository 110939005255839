
.image-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  &__image-info {
    padding-top: 20px;
    display: contents;
  }
}
.image-container > div:only-child {
  grid-column: 1 / -1;
}
@media (max-width: 768px) {
  .image-container {
    grid-template-columns: 1fr;
  }
}
.fullscreen-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
