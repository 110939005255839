
@import '../../css/variables';

.root {
  background-color: #000000;
}

.lowerHeader {
  white-space:nowrap;
}

.editorVideo {
  position: absolute;
  width: 45vw;
  height: 45vw;
  max-width: 612px;
}

.loadingContainer {
  width: 1200px;
  height: 700px;
  position: relative;
  margin: auto;
}

.loadingMessage {
  margin-top: 10vw;
  color: #FFFFFF;
  position: absolute;
  width: 300px;
  right: 200px;
  top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin-bottom: 2rem;
  }

  .v-progress-linear {
    width: 90%;
  }

  .timeRemaining {
    margin-top: 1.5rem;
  }
}
